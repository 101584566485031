import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkoutText } from '../DataFiles/standardText';
import { routerMap } from '../utils/router';

import '../MainForm/MainForm.css';
import PhotoIdForm from './PhotoIdForm';
import DemographicForm from './DemographicForm';
import LoadingModal from './LoadingModal';
import TurnstileWidget from '../Turnstile/TurnstileWidget';

const CheckoutFormContainer = (props) => {
  const {
    formData,
    onPageUpdate,
    currentScreen,
    headerRef
  } = props;
  const [turnstileToken, setTurnstileToken] = useState('');
  const handleTurnstileToken = (token) => {
    setTurnstileToken(token);
  };
  const [localState, setLocalState] = useState({
    filename: '',
    photo: '',
    fullSrc: '',
  });
  const [error, setError] = useState('');
  const [isPendingData, setIsPendingData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [demographicData, setDemographicData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    pharmacyId: '251341',
  });
  const [cardInfo, setCardInfo] = useState({
    cardNum: '',
    expMonth: '',
    expYear: '',
    cvc: '',
  });

  const {
    submitPhoto,
    createVisit,
    // validateTurnstile
  } = routerMap;

  const navigate = useNavigate();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
    return 'Are you sure you want to leave this page, your information is still submitting?';
  };

  const handleCreateVisit = async () => {
    try {
      window.addEventListener('beforeunload', handleBeforeUnload);
      setIsPendingData(true);
      const visitId = await createVisit(
        cardInfo,
        formData,
        demographicData,
        setError,
        'Stripe',
        turnstileToken
      );

      if (visitId) {
        await submitPhoto(visitId, localState, setError, navigate);
      }
    } catch (error) {
      console.error('handleCreateVisit error', error);
    }
    setIsPendingData(false);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  const onPhotoReady = (photoState) => {
    setLocalState({
      ...photoState,
    });
  };

  const updatePage = () => {
    onPageUpdate('submit');
  };

  const isPhotoScreen = () => {
    return currentScreen === 'photo';
  };

  const isSubmitScreen = () => {
    const isSubmit = currentScreen === 'submit';
    return isSubmit;
  };

  return (
    <div>
      <div className='messageBody'>{checkoutText.body}</div>
      {isPhotoScreen() && (
        <PhotoIdForm photoState={localState} onPhotoReady={onPhotoReady} />
      )}
      {isSubmitScreen() && (
        <DemographicForm
          headerRef={headerRef}
          formData={formData}
          setValidated={setValidated}
          onFormValidated={(data, cardData) => {
            setDemographicData(data);
            setCardInfo(cardData);
          }}
        />
      )}

      {error && <div className='checkoutError'>{error}</div>}
      <div className='ccContainer'>
        <TurnstileWidget onTokenChange={handleTurnstileToken} />

        {isSubmitScreen() ? (
          <button
            onClick={handleCreateVisit}
            className='orderButton checkoutButton'
            disabled={!localState.photo.length || !validated || isPendingData}
          >
            Submit your information
          </button>
        ) : (
          <button
            onClick={updatePage}
            className='orderButton checkoutButton'
            disabled={!localState.photo.length}
          >
            Continue
          </button>
        )}
      </div>
      <LoadingModal show={isPendingData} />
    </div>
  );
};

export default CheckoutFormContainer;
